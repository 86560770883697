<template>
  <section class="content-section">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5">$RAT Monster chart.</h2>
        </div>
      </div>
    </div>

    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-3 text-center" style="margin-bottom: 10px;" v-for="nft in nfts" :key="nft">
          <template v-if="nft">
          <router-link v-bind:to="'/rat/' + nft.id">
            <img v-bind:src="nft.metadata.image" class="img-fluid nft" />
          </router-link>
          <br/>
          <template v-if="nft.metadata.name_revealed">{{nft.metadata.name}}<br/></template>
          🪐 Nexus: {{ nft.power }}<br/>
          <MetamaskAddress short="yes" v-bind:address="nft.owner" />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MetamaskAddress from "@/components/MetamaskAddress";
export default {
  components: { MetamaskAddress },
  data() {
    return {
      nfts: [],
    }
  },
  mounted() {
    fetch( this.api_url + '/nightmares/top').then(result => {
      return result.json();
    }).then(data => {
      for(let i in data) {
        this.$store.state.nftContract.tokenURI(data[i].id).then(uri => {
          fetch(uri).then(metadataJson => {
            return metadataJson.json();
          }).then(metadata => {
            this.nfts[i] = {
              id: data[i].id,
              power: data[i].power,
              owner: data[i].owner,
              metadata: metadata,
            };
          })
        })
      }
    });
  }

}
</script>